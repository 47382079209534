import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../styles/Team.css';
 import AdamFillary from '../assets/images/Adam Fillary 2019Headshot_Martin Hobby_MH42331-2400.jpg';
 import LizLathan from '../assets/images/Liz Lathan_PAN Image_WhatsApp Image 2024-10-23 at 01.20.59_c85a7996.jpg';
 import ClareForestier from '../assets/images/Clare Forestier Headshot 2024.jpg';
 import Joanna from '../assets/images/Joanna Headshot_PAN.jpg';
 import Victoria from '../assets/images/Victoria Matey_PAN_Tile.jpg';
 import Nicole from '../assets/images/Nicole Osibodu_Linkedin Profile_2024.jpeg';
 import Merijin from '../assets/images/Merijn color.png';
const Team = () => {
  const settings = {
    dots: false,  // Enable dots for navigation instead of arrows
    arrows: false,  // Disable previous and next arrows
    infinite: false,  // Disable infinite scrolling on larger screens
    speed: 500,
    slidesToShow: 6,  // Show 3 cards on larger screens (desktop)
    slidesToScroll: 1,
    swipe: false,  // Disable swipe on desktop
    responsive: [
      {
        breakpoint: 768,  // Tablet and smaller devices
        settings: {
          slidesToShow: 1,  // Show 1 card on mobile
          slidesToScroll: 1,
          infinite: true,  // Enable infinite scrolling on mobile
          dots: true,
          swipe: true,  // Enable swipe for mobile
          arrows: false,  // Disable previous and next arrows
        }
      }
    ]
  };

  return (
    <div id="team-tab" className="team-container">
      <h2>Meet Our Team</h2>

      <Slider {...settings}>
        <div className="team-member">
          <img src={AdamFillary} alt="Team Member 1" />
          <h3>Adam Fillary</h3>
          <p>APF Networks Ltd</p>
          {/* <small>adam@panglobal.network</small> */}
        </div>
        <div className="team-member">
          <img src="https://cdn-icons-png.flaticon.com/512/6833/6833591.png" alt="Team Member 2" />
          <h3>Jade Emmanuel</h3>
          <p>Director of AI, Data & Innovation Strategy</p>
          {/* <small>Jane@gmail.com</small> */}
        </div>
        <div className="team-member">
        <img src={Joanna} alt="Team Member 3" />
          <h3>Joanna Trew</h3>
          <p>Business Dev</p>
          {/* <small>Sam@gmail.com</small> */}
        </div>
        <div className="team-member">
        <img src={Merijin} alt="Team Member 3" />
          <h3>Merijn Van Buuren</h3>
          <p>Event Technology Advisor</p>
          {/* <small>Sam@gmail.com</small> */}
        </div>
        <div className="team-member">
        <img src="https://cdn1.iconfinder.com/data/icons/bokbokstars-121-classic-stock-icons-1/512/person-man.png" alt="Team Member 3" />
          <h3>Anthony Thompson</h3>
          <p>Graphic Designer</p>
          {/* <small>Sam@gmail.com</small> */}
        </div>
        <div className="team-member">
          <img src="https://cdn1.iconfinder.com/data/icons/bokbokstars-121-classic-stock-icons-1/512/person-man.png" alt="Team Member 4" />
          <h3>Shivam Kumar</h3>
          <p>Website / Other</p>
          {/* <small>Sam@gmail.com</small> */}
        </div>
      </Slider>

      <h2>Esteemed Partners</h2>

      <Slider {...settings}>
        <div className="team-member">
          <img src="https://cdn1.iconfinder.com/data/icons/bokbokstars-121-classic-stock-icons-1/512/person-man.png" alt="Partner 1" />
          <h3>Christina Strohschneider</h3>
          <p>Next Gen Representative</p>
          {/* <small>david@gmail.com</small> */}
        </div>
        <div className="team-member">
          <img src={Nicole} alt="Partner 1" />
          <h3>Nicole Osibodu</h3>
          <p>Engagement Consultant</p>
          {/* <small>david@gmail.com</small> */}
        </div>
        <div className="team-member">
          <img src={Victoria} alt="Partner 2" />
          <h3>Victoria Matey</h3>
          <p>Esteemed Partner</p>
          {/* <small>alice@gmail.com</small> */}
        </div>
        <div className="team-member">
          <img src={ClareForestier} alt="Partner 2" />
          <h3>Clare Forestier</h3>
          <p>Esteemed Partner</p>
          {/* <small>alice@gmail.com</small> */}
        </div>
        <div className="team-member">
          <img src={LizLathan} alt="Partner 2" />
          <h3>Liz Lathan</h3>
          <p>Engagement Consultant</p>
          {/* <small>alice@gmail.com</small> */}
        </div>
      </Slider>
    </div>
  );
};

export default Team;
